import React, { Component } from 'react';
import animation from "../../static/animations/download.json"
import lottie from "lottie-web"

export class DownloadItem extends Component {
    state = {clicked:false}
    componentDidMount() {
        if (this._el) {
            this._animation = lottie.loadAnimation({
                container: this._el,
                renderer: "svg",
                animationData: animation,
                loop: false,
                autoplay: false
            })
        }
    }
    onMouseOver = () => {
        if (this._animation && !this.state.clicked) {
            this._animation.setDirection(1);
            //this._animation.play();
            this._animation.playSegments([this._animation.currentFrame, 20], true); 
        }
    };
    onClick = (e) => {
        //e.preventDefault();
        this.setState({clicked:true});
        if (this._animation) {
            console.log('play rest');
            this._animation.setDirection(1);
            //this._animation.play();
            this._animation.playSegments([this._animation.currentFrame, 60], true); 
        }
    };
    onMouseLeave = () => {
        if (this._animation && !this.state.clicked) {
            this._animation.setDirection(-1);
            this._animation.play();
        }
    };
    render() {
        const { publication } = this.props;
        return (
            <a target="_blank" download={publication.node.data.file.name} onClick={this.onClick} onMouseLeave={this.onMouseLeave} onMouseEnter={this.onMouseOver} href={publication.node.data.file.url} className="download-item" key={publication.node.id}>
                <div className="download-item__image">
                    <div
                        id="animation"
                        ref={el => (this._el = el)}
                        style={{
                            position: "relative"
                        }}
                    />
                </div>
                <div className="download-item__content">
                    <div className="download-item__date">
                        {publication.node.data.date || ''}
                    </div>
                    <h2 className="small">{publication.node.data.title.text}</h2>
                    {publication.node.data.subtitle && publication.node.data.subtitle.text && <h3 className="small">{publication.node.data.subtitle.text}</h3>}
                    <p className="download-item__authors">{publication.node.data.authors ? publication.node.data.authors.text : ''}</p>
                </div>

            </a>
        );
    }
}


class Downloads extends Component {
    render() {
        const {data} = this.props;
        return (
            <div  className="downloads">
                {data.map(publication => {
                    return <DownloadItem key={publication.node.id} publication={publication} />
                })}
            </div>
        );
    }
}

export default Downloads;